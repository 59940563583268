.album-img {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.track {
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.tracknum {
  min-width: 25px;
}

.songname {
  font-style: italic;
}
